import {useEffect, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet-easybutton/src/easy-button';
import 'leaflet.geodesic';
import 'leaflet.heightgraph';
import 'leaflet.heightgraph/dist/L.Control.Heightgraph.min.css';
import {Bullseye as IconBullseye, GraphUp as IconGraph, HouseFill as IconHouse, Trash3Fill as IconTrash} from 'react-bootstrap-icons';
import {mapInteractionEnable, mapInteractionDisable} from '../utils';
import {alertService, elevationService} from '../services';

export function CustomActions() {
    const map = useMap();
    const circleFeatureGroup = L.featureGroup().addTo(map);
    let heightgraph;

    const [bufferCircleRadius, setBufferCircleRadius] = useState(50);
    const [isActionBufferCircleActive, setIsActionBufferCircleActive] = useState(false);

    const addButton = (title, icon, onActive, onInactive) => {
        const iconString = renderToString(icon);
        return L.easyButton({
            'states': [{
                'stateName': 'button-inactive',
                'icon': iconString,
                'title': title,
                'onClick': onInactive,
            }, {
                'stateName': 'button-active',
                'icon': iconString,
                'title': title,
                'onClick': onActive,
            }],
        })
            .setPosition('bottomright')
            .addTo(map);
    };

    const clearDraw = () => {
        map.eachLayer(function(layer){
            if (layer._path !== null && layer.pm !== undefined) {
                layer.remove()
            }
        });
    };

    const createActionBufferCircle = () => {
        const elementId = 'circle-buffer-action';
        const title = 'Circle Buffer';
        const icon = <IconBullseye title={title}/>;
        const button = addButton(title, icon, btn => {
            mapInteractionEnable(map);
            btn.state('button-inactive');
            setIsActionBufferCircleActive(false);
            const container = document.getElementById(elementId);
            container.classList.add('d-none');
        }, btn => {
            mapInteractionDisable(map);
            btn.state('button-active');
            setIsActionBufferCircleActive(true);
            const container = document.getElementById(elementId);
            container.classList.remove('d-none');
        });

        // add additional logic to button
        const buttonContainer = document.querySelectorAll('.leaflet-bottom.leaflet-right .easy-button-container')[0];
        const container = document.createElement('div');
        container.id = elementId;
        container.className = 'leaflet-pm-actions-container d-none';
        const action = document.createElement('div');
        action.className = 'leaflet-pm-action';
        const a = document.createElement('a');
        a.className = 'pe-2';
        a.textContent = `Radius (m)`;
        action.appendChild(a);
        const input = document.createElement('input');
        L.DomEvent.disableClickPropagation(input);
        input.className = 'ps-1';
        input.maxLength = 4;
        input.onkeyup = () => {
            let newRadius = parseInt(input.value, 10);
            if (input.value !== '' && Number.isInteger(newRadius) === false) {
                input.value = bufferCircleRadius;
                newRadius = bufferCircleRadius;
            }

            setBufferCircleRadius(newRadius);
        };
        input.type = 'text';
        input.value = bufferCircleRadius;
        action.appendChild(input);
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionBufferObjects = () => {
        const elementId = 'objects-buffer-action';
        const title = 'Add buffers';
        const button = addButton(title, <IconHouse title={title} />, btn => {
            mapInteractionEnable(map);
            btn.state('button-inactive');
            const container = document.getElementById(elementId);
            container.classList.add('d-none');
        }, btn => {
            mapInteractionDisable(map);
            btn.state('button-active');
            const container = document.getElementById(elementId);
            container.classList.remove('d-none');
        });

        // add additional logic to button
        const buttonContainer = document.getElementsByClassName('easy-button-container')[1];
        const container = document.createElement('div');
        container.id = elementId;
        container.className = 'leaflet-pm-actions-container d-none';
        const action = document.createElement('div');
        action.className = 'leaflet-pm-action';
        [{
            'color': '#FF7518',
            'height': 3,
            'title': 'JBox',
            'width': 10,
        }, {
            'color': '#FF4433',
            'height': 10,
            'title': 'JBox Bail',
            'width': 10,
        }, {
            'color': '#FA8072',
            'height': 25,
            'title': 'IECharge Commune',
            'width': 10,
        }, {
            'color': '#FFAA33',
            'height': 27,
            'title': 'IECharge Département',
            'width': 11,
        }].forEach(element => {
            const a = document.createElement('a');
            a.className = 'd-block';
            a.textContent = element.title;
            a.onclick = () => {
                // apparently this is the only way to set a fixed size polygon: draw circles and pick coordinates from boundaries
                const center = map.getCenter();
                const circleHeight = new L.circle(center, {'radius': element.height/2}).addTo(map);
                const circleHeightBounds = circleHeight.getBounds();
                map.removeLayer(circleHeight);
                const circleWidth = new L.circle(center, {'radius': element.width/2}).addTo(map);
                const circleWidthBounds = circleWidth.getBounds();
                map.removeLayer(circleWidth);
                L.rectangle([[circleHeightBounds.getNorthWest().lat, circleWidthBounds.getNorthWest().lng], [circleHeightBounds.getSouthEast().lat, circleWidthBounds.getSouthEast().lng]], {
                    'color': element.color,
                    'pmIgnore': false,
                    'weight': 2,
                }).addTo(map);
            };
            action.appendChild(a);
        });
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionDelete = () => {
        return L.easyButton(renderToString(<IconTrash />), () => {
            clearDraw();
        }, 'Remove all')
            .setPosition('bottomright')
            .addTo(map);
    };

    const createActionHeightgraph = () => {
        const elementId = 'elevation-action';
        const title = 'Elevation';
        const onActionHeightgraphBegin = btn => {
            mapInteractionDisable(map);
            btn.state('button-active');
            const container = document.getElementById(elementId);
            container.classList.remove('d-none');

            map.pm.enableDraw('Line');
            map.on('pm:create', event => {
                // convert clicked points to geodesic line, i.e. extrapolating their lat/lng to a mappable line
                const coordinates = new L.geodesic(event.layer.getLatLngs());
                drawHeightgraph(coordinates.getLatLngs()[0]);
                onActionHeightgraphEnd(btn);
            });
        };
        const onActionHeightgraphEnd = btn => {
            mapInteractionEnable(map);
            btn.state('button-inactive');
            const container = document.getElementById(elementId);
            container.classList.add('d-none');

            map.pm.disableDraw();
            map.off('pm:drawend');
        };
        const button = addButton(title, <IconGraph title={title} />, onActionHeightgraphEnd, onActionHeightgraphBegin);
        const buttonContainer = document.getElementsByClassName('easy-button-container')[1];
        const container = document.createElement('div');
        container.id = elementId;
        container.className = 'leaflet-pm-actions-container d-none';
        buttonContainer.appendChild(container);
        return button;
    };

    const drawCircle = (event, radius) => {
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': 1,
        }).addTo(circleFeatureGroup); // this is a hack to mark the center of the circle... through another circle :/
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': radius,
        }).addTo(circleFeatureGroup);
        circleFeatureGroup.bringToFront();
    };

    const drawHeightgraph = latLngs => {
        elevationService.get(latLngs)
            .then(response => {
                if (response === null) {
                    alertService.error('Problems with obtaining elevation data');
                    return;
                }

                let prevCoordinate = null;
                let distance = 0; // in m, needed for slope calculation
                const coordinates = response.map(data => {
                    let coordinate = [data.location.lat, data.location.lng];
                    if (prevCoordinate !== null) {
                        distance += map.distance(prevCoordinate, coordinate);
                    }
                    prevCoordinate = coordinate;

                    return [data.location.lng, data.location.lat, data.elevation];
                });
                const slopePercentage = ((response[response.length - 1].elevation - response[0].elevation) / distance) * 100;

                const data = [{
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': coordinates,
                        },
                        'properties': {
                            'attributeType': '',
                        },
                    }],
                    'properties': {
                        'Creator': 'Google Elevation API',
                        'records': 1,
                        'summary': `Slope Percentage: ${slopePercentage.toFixed(2)}%`,
                    }
                }];

                heightgraph.addTo(map);
                heightgraph.addData(data);

                // bottom left align
                const panel = document.getElementById('panel');
                const left = true === panel.classList.contains('hiding') ? 0 : panel.offsetWidth;
                document.querySelector('.heightgraph').style.left = `${left}px`;
            });
    };

    useEffect(() => {
        if (isActionBufferCircleActive === true) {
            // @TODO: ensure that this bind is getting triggered first, so that stopPropagation can work
            map.on('click', event => {
                drawCircle(event, bufferCircleRadius);
            });
        }

        return () => {
            map.off('click');
        };
    }, [bufferCircleRadius, isActionBufferCircleActive]);

    useEffect(() => {
        const actionDelete = createActionDelete();
        const actionBufferObjects = createActionBufferObjects();
        const actionBufferCircle = createActionBufferCircle();
        const actionHeightgraph = createActionHeightgraph();

        heightgraph = L.control.heightgraph({
            'expandCallback': expanded => {
                if (false === expanded) {
                    heightgraph.remove();
                    clearDraw();
                }
            },
            'position': 'bottomleft',
            'width': 500,
        });

        return () => {
            actionBufferCircle.remove();
            actionBufferObjects.remove();
            actionDelete.remove();
            actionHeightgraph.remove();
        };
    }, []);
}
