import {useEffect} from 'react';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import {mapInteractionDisable, mapInteractionEnable} from '../utils';

export function PolylineMeasure() {
    const map = useMap();

    useEffect(() => {
        const polylineMeasure = (L.control).polylineMeasure({
            'clearMeasurementsOnStop': false,
            'position': 'bottomright',
            'showBearings': false, // show azimut
            'showClearControl': true,
            'unit': 'kilometres',
        });
        polylineMeasure.addTo(map);
        map.on('pm:globaldrawmodetoggled', e => {
            if (true === e.enabled) {
                mapInteractionDisable(map);
            } else {
                mapInteractionEnable(map);
            }
        });

        return () => {
            map.removeControl(polylineMeasure);
        };
    }, []);
}
