import {useEffect, useId, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import ReactSlider from 'react-slider';

/**
 * Atlas-layer filter form field. There are three field types: CHOICES, FLAG, NUM.
 *  - CHOICES is an array of options.
 *  - FLAG is a single boolean value.
 *  - NUM is an array with a >= and <= value, by default this field is locked, i.e. won't submit until clicked.
 *
 * @param field             the field with all its properties to make the filter function
 * @param isDisabled        whether the field is disabled upon load
 * @param updateValue       does what it needs to do with the updated value
 * @param titleChoice       the form group's title, only used for choices
 */
export function FilterLineFormField({field, isDisabled, updateValue, titleChoice}) {
    const id = useId();
    const isFloat = false === Number.isInteger(field.step);

    const [defaultValues, setDefaultValues] = useState(null); // NUM's default values to reset upon lock
    const [isLocked, setIsLocked] = useState(false);
    const [value, setValue] = useState(field.value || field.defaultValue || null);

    // init value for NUM
    useEffect(() => {
        // compute initial values for NUM, which requires slider ranges to be preset for usability reasons
        if ('NUM' === field.type) {
            let newValues;

            // init to [min,max] of NULL values
            if (null === value[0] && null === value[1]) {
                newValues = [field.min, field.max];
                setIsLocked(true);
            } else {
                let newValueMax = value[1];
                let newValueMin = value[0];
                if (null === newValueMax) {
                    newValueMax = field.max;
                }
                if (null === newValueMin) {
                    newValueMin = field.min;
                }
                // console.log('newValue is mix of defaultValue and min/max')
                newValues = [newValueMin, newValueMax];
            }

            // init float values to support decimals, if set
            if (true === isFloat) {
                if (null !== newValues[0]) {
                    newValues[0] *= 10;
                }
                if (null !== newValues[1]) {
                    newValues[1] *= 10;
                }
            }

            setDefaultValues(newValues);
            setValue(newValues);
        }

        return () => {
            setIsLocked(false);
            setDefaultValues(null);
            setValue(field.value || field.defaultValue || null);
        };
    }, []);

    // 'NUM' only
    const setValues = values => {
        setValue(values);
        if (true === isFloat) {
            updateValue([values[0]/10, values[1]/10]);
        } else {
            updateValue(values);
        }
    };

    const switchOnChange = localValue => {
        if (false === localValue) {
            localValue = '';
        }
        setValue(localValue);
        updateValue(localValue);
    };

    switch (field.type) {
        case 'CHOICE':
            return (
                <>
                    <div className="mb-1">{titleChoice}</div>
                    {
                        [...field.choices]
                            .sort()
                            .map((choice, key) =>
                                <Form.Check
                                    checked={true === value.includes(choice)}
                                    className="fst-italic"
                                    disabled={true === isDisabled}
                                    id={`${field.field}-${choice}-${id}`}
                                    key={key}
                                    label={choice}
                                    onChange={() => {
                                        // value is an array of values
                                        let localValue;
                                        if (true === value.includes(choice)) { // remove
                                            localValue = value.filter(v => choice !== v);
                                        } else { // add
                                            localValue = [
                                                ...value,
                                                choice,
                                            ];
                                        }

                                        setValue(localValue);
                                        updateValue(localValue);
                                    }}
                                    type="switch"
                                />
                            )
                    }
                </>
            );
            
        case 'FLAG':
            return (
                <Form.Switch
                    checked={true === value}
                    className="fst-italic"
                    disabled={true === isDisabled}
                    label={<span onClick={() => switchOnChange(!value)}>{field.name}</span>}
                    onChange={event => switchOnChange(event.currentTarget.checked)}
                />
            );

        case 'NUM':
            let labelValueFrom;
            let labelValueTo;

            // ReactSlider does not support decimals, hence multiply and divide accordingly
            let max;
            let min;
            let step;
            if (true === isFloat) {
                labelValueFrom = (value[0]/10).toFixed(1);
                labelValueTo = (value[1]/10).toFixed(1);
                max = field.max*10;
                min = field.min*10;
                step = field.step*10;
            } else {
                labelValueFrom = value[0];
                labelValueTo = value[1];
                max = field.max;
                min = field.min;
                step = field.step;
            }

            return (
                <InputGroup
                    className="my-2"
                    onClick={() => {
                        // unlock
                        if (true === isLocked) {
                            setIsLocked(false);
                            setValues(defaultValues);
                        }
                    }}
                    size="sm"
                >
                    <Form.Label className="mb-0" size="sm">
                        {true === isDisabled || true === isLocked
                            ?
                            field.name
                            :
                            <>
                                {field.name}{'' !== value && <span className="fw-bold ps-1">{labelValueFrom} → {labelValueTo}</span>}
                                <a className="ms-2" onClick={event => {
                                    // lock
                                    event.stopPropagation();
                                    setIsLocked(true);
                                    setValue(defaultValues);
                                    updateValue([null, null]);
                                }} role="button">clear</a>
                            </>
                        }
                    </Form.Label>
                    <ReactSlider
                        ariaLabel={['Min', 'Max']}
                        disabled={true === isDisabled || true === isLocked}
                        onChange={setValues}
                        max={max}
                        min={min}
                        minDistance={step}
                        value={value}
                    />
                </InputGroup>
            );
    }
}
