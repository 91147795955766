import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Question as IconQuestion} from 'react-bootstrap-icons';
import {Button, Form} from 'react-bootstrap';
import {setGeneral as setLayersGeneral, setSearch as setLayersSearch} from '../redux/layersSlice';
import {
    setLayers as setUserLayers,
    setModes as setUserModes,
    setSelection as setUserSelection
} from '../redux/userSlice';
import {getAvailableModes, isObjectEmpty, setUserProfile} from '../utils';

export function Profile() {
    const dispatch = useDispatch();

    const country = useSelector(state => state.country.value.current);
    const layersGeneral = useSelector(state => state.layers.value.general);
    const layersSearch = useSelector(state => state.layers.value.search);
    const userLayers = useSelector(state => state.user.value.layers);
    const userModes = useSelector(state => state.user.value.modes);
    const userSelection = useSelector(state => state.user.value.selection);

    const [availableLayers, setAvailableLayers] = useState([]);
    const [availableModes, setAvailableModes] = useState(getAvailableModes(country));
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // init available layers based on user's layers once both general and search layers are set
    useEffect(() => {
        if (false === isInitialized &&
            null !== layersGeneral &&
            null !== layersSearch &&
            null !== userLayers) {
            setAvailableLayers(
                [...layersGeneral, ...layersSearch] // take both general and search layers
                    .map(localLayer => ({
                        ...localLayer,
                        'isActive': undefined !== userLayers?.[country]?.[localLayer._id], // set active if active user layer
                    }))
                    .sort((a, b) => a.name > b.name ? 1 : -1)
            );
            setIsInitialized(true);
        }
    }, [layersGeneral, layersSearch]);

    useEffect(() => {
        setAvailableModes(getAvailableModes(country));
    }, [country]);

    const changeMode = event => {
        // set and persist mode
        const newMode = event.target.value;
        const localUserModes = {...userModes};
        localUserModes[country] = newMode;
        dispatch(setUserModes(localUserModes));

        // preset default layers as new selection, without persisting to the user's layers
        const newActiveLayers = availableModes?.[newMode]?.activeLayers?.[country];
        if (undefined !== newActiveLayers) {
            // update available layers on which the selectable list is based
            setAvailableLayers(availableLayers.map(layer => ({
                ...layer,
                'isActive': true === newActiveLayers.includes(layer.name),
            })));
        }
    };

    const renderLayerCheckbox = layer => (
        <Form.Check
            checked={true === layer.isActive}
            disabled={true === isSubmitting}
            id={layer._id} // enables click on label trigger onChange
            key={layer._id}
            label={layer.name}
            name={layer._id}
            onChange={() => {
                setAvailableLayers(
                    availableLayers.map(availableLayer => {
                        // toggle isActive of clicked layer
                        if (layer._id === availableLayer._id) {
                            return {
                                ...availableLayer,
                                'isActive': !availableLayer.isActive,
                            };
                        }

                        // don't touch the others
                        return availableLayer;
                    })
                );
            }}
            type="checkbox"
        />
    );

    return (
        <>
            {false === isObjectEmpty(availableModes) && <>
                <h5>
                    Mode
                    <IconQuestion style={{'cursor': 'help'}} title={"Changing the mode will also set your layer selection to the mode's default layers."}/>
                </h5>
                <Form.Select
                    aria-label="Select your application mode"
                    onChange={changeMode}
                    value={userModes[country]}
                >
                    {Object.keys(availableModes).sort((a, b) => availableModes[a].label > availableModes[b].label ? 1 : -1).map(key =>
                        <option
                            key={key}
                            value={key}
                        >
                            {availableModes[key].label}
                        </option>
                    )}
                </Form.Select>
            </>}
            {0 < availableLayers.length && <>
                <h5 className="mt-2">
                    Layers
                    <IconQuestion style={{'cursor': 'help'}} title={"Selected layers will be searched and displayed on the map. Striped layers are general, meaning they are independent from search and always show."}/>
                </h5>
                <Form>
                    <Form.Group className="ps-2 py-1 stripes">
                        <Form.Label className="fw-bold mb-1">General</Form.Label>
                        {availableLayers.filter(availableLayer =>
                            country === availableLayer.country &&
                            'general' === availableLayer.scope
                        ).map(availableLayer => renderLayerCheckbox(availableLayer))}
                    </Form.Group>
                    <Form.Group className="ps-2 py-1">
                        <Form.Label className="fw-bold mb-1">Search</Form.Label>
                        {availableLayers.filter(availableLayer =>
                            country === availableLayer.country &&
                            'general' !== availableLayer.scope
                        ).map(availableLayer => renderLayerCheckbox(availableLayer))}
                    </Form.Group>
                    <div className="mt-3">
                        <Button
                            disabled={true === isSubmitting}
                            onClick={() => {
                                setIsSubmitting(true);

                                // update layers to ensure that layer changes are immediately reflected on the map
                                const localLayersGeneral = availableLayers.filter(availableLayer => 'general' === availableLayer.scope);
                                dispatch(setLayersGeneral(localLayersGeneral));
                                const localLayersSearch = availableLayers.filter(availableLayer => 'search' === availableLayer.scope);
                                dispatch(setLayersSearch(localLayersSearch));

                                // trigger search layer reload (requiring a prior search) if there are active search layers
                                if (null !== userSelection &&
                                    0 < localLayersSearch.filter(layer => true === layer.isActive).length) {
                                    dispatch(setUserSelection({
                                        ...userSelection,
                                    }));
                                }

                                // update user layers
                                const localUserLayers = {...userLayers};
                                localUserLayers[country] = {};
                                availableLayers.filter(availableLayer => true === availableLayer.isActive).forEach(availableLayer => {
                                    localUserLayers[country][availableLayer._id] = availableLayer.name;
                                });
                                dispatch(setUserLayers(localUserLayers));

                                // persist to local storage
                                setUserProfile({
                                    'country': country,
                                    'layers': localUserLayers,
                                    'modes': userModes,
                                });

                                setIsSubmitting(false);
                            }}
                            size="sm"
                        >Apply</Button>
                    </div>
                </Form>
            </>}
        </>
    );
}
