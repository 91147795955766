import {fetchWithMsal, handleResponse} from '../utils';

export const operatorService = {
    autocomplete,
    getElement,
    getElements,
    near,
    intersect,
};

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/operators`;
const baseUrlV2 = `${process.env.REACT_APP_API_BASE_URL}/v2/operators`;
const sharedParams = `&size=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`;

async function autocomplete(layerId, fields, value, limit = 5) {
    const compound = {
        'must': [
            {
                'equals': {
                    'path': 'layer_id',
                    'value': layerId,
                },
            },
        ],
        'should': [],
        'minimumShouldMatch': 1,
    };

    fields.forEach(field => {
        compound.should.push({
            'autocomplete': {
                'path': field,
                'tokenOrder': 'any',
                'query': value.replaceAll('-', ' '),
            },
        });
    });

    return paginate(compound, limit, false);
}

async function getElement(layerId, fieldsMust = {}, fieldsShould = {}) {
    const element = await getElements(layerId, fieldsMust, fieldsShould, [], null, 1);
    return 0 === element.length ? null : element;
}

async function getElements(layerId, fieldsMust = {}, fieldsShould = {}, geometries = [], embeddedDocument = null, limit = null) {
    const compound = {
        'must': [
            {
                'equals': {
                    'path': 'layer_id',
                    'value': layerId,
                },
            },
        ],
        'should': [],
    };

    if ('equals' in fieldsMust) {
        fieldsMust.equals.forEach(field => {
            compound.must.push({
                'equals': {
                    'path': field.key,
                    'value': field.value,
                },
            });
        });
    }
    if ('in' in fieldsMust) {
        fieldsMust.in.forEach(field => {
            compound.must.push({
                'in': {
                    'path': field.key,
                    'value': field.value,
                },
            });
        });
    }
    if ('phrase' in fieldsMust) {
        fieldsMust.phrase.forEach(field => {
            compound.must.push({
                'phrase': {
                    'path': field.key,
                    'query': field.value,
                },
            });
        });
    }
    if ('range' in fieldsMust) {
        fieldsMust.range.forEach(field => {
            const rangeObject = {
                'path': field.key,
            };
            if (null !== field.value[0]) { // only add >= if not null
                rangeObject.gte = field.value[0];
            }
            if (null !== field.value[1]) { // only add <= if not null
                rangeObject.lte = field.value[1];
            }
            compound.must.push({
                'range': rangeObject,
            });
        });
    }

    if ('equals' in fieldsShould) {
        fieldsShould.equals.forEach(field => {
            compound.should.push({
                'equals': {
                    'path': field.key,
                    'value': field.value,
                },
            });
        });
    }
    if ('in' in fieldsShould) {
        fieldsShould.in.forEach(field => {
            compound.should.push({
                'in': {
                    'path': field.key,
                    'value': field.value,
                },
            });
        });
    }
    if ('phrase' in fieldsShould) {
        fieldsShould.phrase.forEach(field => {
            compound.must.push({
                'phrase': {
                    'path': field.key,
                    'query': field.value,
                },
            });
        });
    }
    if ('range' in fieldsShould) {
        fieldsShould.range.forEach(field => {
            compound.must.push({
                'range': {
                    'gte': parseFloat(field.value), // works also as a string, but hey: this is much cleaner
                    'path': field.key,
                },
            });
        });
    }

    // deal with multiple geometries
    const geometriesSearchType = 1 === geometries.length ? 'must' : 'should';
    geometries.forEach(geometry => {
        compound[geometriesSearchType].push({
            'geoShape': {
                'geometry': geometry,
                'path': 'geometry',
                'relation': 'intersects',
            },
        });
    });

    if (null !== embeddedDocument) {
        const embeddedDocumentCompound = {
            'must': [],
            'should': [],
        };

        if ('equals' in embeddedDocument.fieldsMust) {
            embeddedDocument.fieldsMust.equals.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'equals': {
                        'path': field.key,
                        'value': field.value,
                    },
                });
            });
        }
        if ('in' in embeddedDocument.fieldsMust) {
            embeddedDocument.fieldsMust.in.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'in': {
                        'path': field.key,
                        'value': field.value,
                    },
                });
            });
        }
        if ('range' in embeddedDocument.fieldsMust) {
            embeddedDocument.fieldsMust.range.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'range': {
                        'gte': parseFloat(field.value), // works also as a string, but hey: this is much cleaner
                        'path': field.key,
                    },
                });
            });
        }
        if ('phrase' in embeddedDocument.fieldsMust) {
            embeddedDocument.fieldsMust.phrase.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'phrase': {
                        'path': field.key,
                        'query': field.value,
                    },
                });
            });
        }

        if ('equals' in embeddedDocument.fieldsShould) {
            embeddedDocument.fieldsShould.equals.forEach(field => {
                embeddedDocumentCompound.should.push({
                    'equals': {
                        'path': field.key,
                        'value': field.value,
                    },
                });
            });
        }
        if ('in' in embeddedDocument.fieldsShould) {
            embeddedDocument.fieldsShould.in.forEach(field => {
                embeddedDocumentCompound.should.push({
                    'in': {
                        'path': field.key,
                        'value': field.value,
                    },
                });
            });
        }
        if ('range' in embeddedDocument.fieldsShould) {
            embeddedDocument.fieldsShould.range.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'range': {
                        'gte': parseFloat(field.value), // works also as a string, but hey: this is much cleaner
                        'path': field.key,
                    },
                });
            });
        }
        if ('phrase' in embeddedDocument.fieldsShould) {
            embeddedDocument.fieldsShould.phrase.forEach(field => {
                embeddedDocumentCompound.must.push({
                    'phrase': {
                        'path': field.key,
                        'query': field.value,
                    },
                });
            });
        }

        if (0 < embeddedDocumentCompound.must.length || 0 < embeddedDocumentCompound.should.length) {
            if (0 < embeddedDocumentCompound.should.length) {
                embeddedDocumentCompound.minimumShouldMatch = 1;
            }
            compound.must.push({
                'embeddedDocument': {
                    'path': embeddedDocument.path,
                    'operator': {
                        'compound': embeddedDocumentCompound,
                    },
                },
            });
        }
    }

    // require at least a match when we have 'should' fields
    if (0 < compound.should.length) {
        compound.minimumShouldMatch = 1;
    }

    return paginate(compound, limit);
}

async function intersect(layerId, elementId) {
    return fetchWithMsal(`${baseUrlV2}/intersect/${layerId}/${elementId}?&step=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`, {
        method: 'HEAD',
    })
        .then(response => {
            let headers = Object.fromEntries(response.headers.entries());
            let proms = Object.keys(headers)
                .filter(key => 'x-jsp-page-' === key.substring(0, 11))
                .map(key => intersectOne(layerId, elementId, headers[key]));
            proms.push(intersectOne(layerId, elementId));
            return Promise.all(proms);
        })
        .then(pages => {
            return pages.map(page => page.items).flat(1);
        })
        .catch(error => {
            console.error(`operatorService.intersect: Layer ID: ${layerId} ; Element ID: ${elementId} ;`, error);
            return [];
        });
}

function intersectOne(layerId, elementId, searchAfter = null) {
    let url = `${baseUrlV2}/intersect/${layerId}/${elementId}?${sharedParams}`;
    if (searchAfter !== null) {
        url += `&searchAfter=${encodeURIComponent(searchAfter)}`;
    }

    return fetchWithMsal(url)
        .then(handleResponse)
        .catch((error) => {
            console.error(`operatorService.intersectOne: ${url}`, error);
            return [];
        });
}

// maxDist in meters
async function near(layerId, coordinates, maxDist = 1) {
    const url = `${baseUrl}/near/${layerId}/${coordinates[0]}/${coordinates[1]}?max_dist=${maxDist}`;
    return await fetchWithMsal(url)
        .then(handleResponse)
        .catch((error) => {
            console.error(`operatorService.near: ${url}`, error);
            return [];
        });
}

// not to be exposed, paginates search
async function paginate(compound, limit, sort = true) {
    // set default page size
    const limitDefault = parseInt(process.env.REACT_APP_API_MAX_PAGE_SIZE);
    if (null === limit) {
        limit = limitDefault;
        // avoid pagination when requested page size matches default page size
    } else if (limit === limitDefault) {
        limit++;
    }

    // run search to get first response, if sort true then results are sorted by display_name
    let response = await search(compound, limit, sort);

    // no results, return empty
    if (0 === response.length) {
        return [];
    }

    // get one, return one
    if (1 === limit) {
        return response[0];
    }

    // get custom count, return them
    if (limit !== limitDefault) {
        return response;
    }

    let results = [...response];
    // loop through responses
    while (response.length === limit) {
        response = await search(compound, limit, sort, response[response.length - 1].searchAfter);
        results = [...results, ...response];
    }

    return results;
}

// not to be exposed, used by paginate
function search(compound, limit, sort = true, searchAfter = null) {
    let url = `${baseUrlV2}/search?sort=${sort}`;
    if (null !== limit) {
        url += `&limit=${limit}`;
    }
    if (null !== searchAfter) {
        url += `&searchAfter=${encodeURIComponent(searchAfter)}`;
    }

    return fetchWithMsal(url, {
        'method': 'POST',
        'body': JSON.stringify({
            'index_name': 'layer_element_index',
            'compound': compound,
        }),
    })
        .then(handleResponse)
        .catch((error) => {
            console.error('operatorService.search', url, compound, error);
            return [];
        });
}
