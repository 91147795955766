import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMap} from 'react-leaflet/hooks';
import { Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {
    ArrowBarUp as IconArrowBarUp,
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Filter as IconFilter
} from 'react-bootstrap-icons';
import {FilterLineFormField, FilterLineFormFieldLegacy} from '.';
import {setFilters as setUserFilters, setSubstation as setUserSubstation} from '../redux/userSlice';
import {operatorService} from '../services';
import {
    copyDeep,
    DEFAULT_NON_ATLAS_FILTER,
    filter,
    getGeoJsonFromElements,
    isObject,
    isObjectEmpty,
    isObjectEqual,
    MODE_KEY_ACCOUNTS,
    MODE_PROSPECTION
} from '../utils';

/**
 * Every non-empty layer has its filter line.
 * This component embeds FilterLineFormField Atlas-powered layers, or the *Legacy for the others.
 *
 * @param dataLayer                 the filter's layer
 * @param lastHighlightedKey        required to visually highlight the right filter upon change
 * @param layers                    access to all filters, either general or search, to allow for data reset
 * @param setLastHighlightedKey
 * @param updateDataLayer           renders the filtered layer
 */
export function FilterLine({dataLayer, lastHighlightedKey, layers, setLastHighlightedKey, updateDataLayer}) {
    const dispatch = useDispatch();
    const map = useMap();
    const defaultFormValues = { // store default values to allow for reset
        0: dataLayer.layer.defaults,
    };

    const country = useSelector(state => state.country.value.current);
    const layersTypes = useSelector(state => state.layers.value.types);
    const userFilters = useSelector(state => state.user.value.filters); // redux-state filters, update whenever user makes a change to the filter (atlas layers) or whenever submitted (non-atlas layers)
    const userModes = useSelector(state => state.user.value.modes);

    const [filters, setFilters] = useState({}); // component-state filters and their data, for atlas layers only
    const [filterKey, setFilterKey] = useState(0); // key of a list of form values
    const [formValues, setFormValues] = useState(defaultFormValues); // for non-atlas layers only
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(true === dataLayer.layer.isFilterOpen);

    useEffect(() => {
        if (false === userFilters?.[dataLayer.layer._id]?.isAtlas) {
            setFormValues(userFilters[dataLayer.layer._id].formValues);
        }

        return () => {
            setFormValues(defaultFormValues);
        }
    }, [userFilters]);

    useEffect(() => {
        // init filters based on layer's search_fields and user filters
        if (0 < dataLayer.layer.search_fields.length) {
            // init interface filters for that layer
            const localFilters = {
                'equals': [],
                'in': [],
                'phrase': [],
                'range': [],
            };
            // keys that require choices to be retrieved from elements
            const searchChoicesKeys = [];

            dataLayer.layer.search_fields.forEach(searchField => {
                const key = `metadata.${searchField.field}`;
                let defaultValue;
                switch (searchField.type) {
                    case 'CHOICE': // checkbox input
                        let choices = searchField.choices;
                        const userFilterChoices = userFilters?.[dataLayer.layer._id]?.in || [];

                        // use previously retrieved choices from component-state filter, if any
                        if ('in' in filters) {
                            const filter = filters.in.filter(filter => filter.field === key && 0 < filter.choices.length).pop();
                            if (undefined !== filter) {
                                choices = filter.choices;
                            }
                        // otherwise, attempt to get choices from redux-state userFilter
                        } else {
                            const matchingUserFilter = userFilterChoices.filter(userFilter => key === userFilter.key).pop();
                            if (undefined !== matchingUserFilter) {
                                choices = matchingUserFilter.choices;
                            }
                        }

                        // get default values from user filter, knowing that we have to aggregate several values
                        defaultValue = getDefaultValue(key, userFilterChoices);
                        if ('' === defaultValue) {
                            defaultValue = [];
                        }

                        // add search field to filter
                        localFilters.in.push({
                            ...searchField,
                            'choices': choices,
                            'defaultValue': defaultValue,
                            'field': key,
                            'value': defaultValue, // array of choices
                        });

                        // no choices provided, add key
                        if (true === Array.isArray(searchField.choices) &&
                            0 === searchField.choices.length) {
                            searchChoicesKeys.push(key);
                        }

                        break;

                    case 'FLAG': // switch (checkbox) input
                        defaultValue = getDefaultValue(key, userFilters[dataLayer.layer._id]?.equals);
                        // add search field to filter
                        localFilters.equals.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'field': key,
                            'value': defaultValue,
                        });
                        break;

                    case 'NUM': // range (slider) input
                        // default value should either be coming from user filters, or be [null,null]
                        defaultValue = getDefaultValue(key, userFilters[dataLayer.layer._id]?.range);
                        if ('' === defaultValue) {
                            defaultValue = [null, null];
                        }

                        // add search field to filter
                        localFilters.range.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'field': key,
                            'value': defaultValue,
                        });
                        break;
                }
            });

            // get available choices from elements
            if (0 < searchChoicesKeys.length) {
                // for all elements
                dataLayer.layer.data.forEach(element => {
                    // for all required choices, determine the element's value matching the key
                    searchChoicesKeys.forEach(searchChoicesKey => {
                        const choicesKeys = searchChoicesKey
                            .replace('metadata.', '') // remove leading metadata, which is represented by the element's properties
                            .split('.')
                        ;
                        const firstLevelKey = choicesKeys[0];
                        const secondLevelKey = choicesKeys[1];

                        let firstLevelProperties = element.properties?.[firstLevelKey];

                        // we matched, exit loop
                        if (undefined === firstLevelProperties) {
                            return;
                        }

                        const choicesValues = [];
                        // attempt matching a nested value (either in an array or an object), e.g. code from "substations.code"
                        if (true === Array.isArray(firstLevelProperties)) {
                            for (const firstLevelProperty of firstLevelProperties) {
                                if (true === secondLevelKey in firstLevelProperty &&
                                    false === choicesValues.includes(firstLevelProperty[secondLevelKey])) {
                                    choicesValues.push(firstLevelProperty[secondLevelKey]);
                                }
                            }
                        } else if (undefined !== firstLevelProperties?.[secondLevelKey] &&
                                   false === choicesValues.includes(firstLevelProperties[secondLevelKey])) {
                            choicesValues.push(firstLevelProperties[secondLevelKey]);
                        }

                        // store the matched value on choices (only works for search fields of type 'in'), if new
                        for (const [searchFieldKey, searchField] of Object.entries(localFilters.in)) {
                            if (searchField.field === searchChoicesKey) {
                                localFilters.in[searchFieldKey].choices = [
                                    ...new Set([ // convert to set and back to remove duplicates
                                        ...searchField.choices,
                                        ...choicesValues,
                                    ]),
                                ];
                                // we matched, exit loop
                                break;
                            }
                        }
                    });
                });

                setFilters(localFilters);
            }
        } else {
            setShow(true === dataLayer.layer.isFilterOpen);
        }

        return () => {
            setFilters({});
        };
    }, [dataLayer.layer]);

    // disable filter button when one of the form values is invalid
    useEffect(() => {
        if (false === isObjectEqual(formValues, defaultFormValues)) {
            let isValid = false;

            // invalid if empty
            if (JSON.stringify(DEFAULT_NON_ATLAS_FILTER) !== JSON.stringify(formValues)) {
                isValid = isFormValuesValid(formValues);
            }

            setIsLoading(false === isValid);
        }

        return () => {
            setIsLoading(false);
        };
    }, [formValues]);

    const _updateUserFilterLegacy = localFormValues => {
        const localUserFilters = copyDeep(userFilters);
        localUserFilters[dataLayer.layer._id].formValues = localFormValues;
        dispatch(setUserFilters(localUserFilters));
    };

    const addFieldsFromUserFilter = (localUserFilter, type, fieldsMust, fieldsShould, fieldsEmbeddedDocument) => {
        localUserFilter[type].forEach(filter => {
            // skip if choice filter without values
            if ('in' === type && 0 === filter.value.length) {
                return;
            }
            // skip if range filter with NULL values
            if ('range' === type && null === filter.value[0] && null === filter.value[1]) {
                return;
            }
            // skip if NULL value
            if (null === filter.value) {
                return;
            }

            const keyValue = {
                'key': filter.key,
                'value': filter.value,
            };
            if (true === filter.embeddedDocument) {
                if ('should' === filter.condition) {
                    fieldsEmbeddedDocument.fieldsShould[type].push(keyValue);
                } else { // must
                    fieldsEmbeddedDocument.fieldsMust[type].push(keyValue);
                }
            } else if ('should' === filter.condition) {
                fieldsShould[type].push(keyValue);
            } else { // must
                fieldsMust[type].push(keyValue);
            }
        });

        return [fieldsMust, fieldsShould, fieldsEmbeddedDocument];
    };

    // persist to user filters and filter through backend
    const formSubmit = localUserFilter => {
        setIsLoading(true);

        // if we have only one substation filter selected, make this the user's substation
        const substationsFilters = localUserFilter.in
            .filter(filter => 'metadata.substations.code' === filter.key && 1 === filter.value.length)
            .pop();
        if (undefined !== substationsFilters) {
            operatorService.getElement(layersTypes.substation._id, {
                'phrase': [{
                    'key': 'display_name',
                    'value': substationsFilters.value[0],
                }],
            })
                .then(substation => {
                    if (null !== substation) {
                        dispatch(setUserSubstation(substation));
                    }
                });
        }

        let fieldsMust = {
            'equals': [],
            'in': [],
            'phrase': [],
            'range': [],
        };
        let fieldsShould = copyDeep(fieldsMust); // init just like must
        let fieldsEmbeddedDocument = {
            'path': 'metadata.substations',
            'fieldsMust': copyDeep(fieldsMust), // init just like must
            'fieldsShould': copyDeep(fieldsMust), // init just like must
        };

        ['equals', 'in', 'phrase', 'range'].forEach(type => {
            [fieldsMust, fieldsShould, fieldsEmbeddedDocument] = addFieldsFromUserFilter(localUserFilter, type, fieldsMust, fieldsShould, fieldsEmbeddedDocument);
        });

        operatorService.getElements(dataLayer.layer._id, fieldsMust, fieldsShould, localUserFilter.geometries, fieldsEmbeddedDocument)
            .then(elements => {
                updateDataLayer({
                    ...dataLayer,
                    'layer': {
                        ...dataLayer.layer,
                        'data': getGeoJsonFromElements(elements),
                    },
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // filter on frontend
    const formSubmitLegacy = () => {
        setIsLoading(true);

        _updateUserFilterLegacy(formValues);

        updateDataLayer({
            ...dataLayer,
            'layer': {
                ...dataLayer.layer,
                'data': filter(formValues, dataLayer.layer.data),
            },
        });

        setIsLoading(false);
    };

    // combine first and second level keys of all elements for exhaustive filtering
    const getAvailableKeys = () => {
        let availableKeys = {};
        dataLayer.layer.data.forEach(element => {
            if ('properties' in element) {
                Object.keys(element.properties).forEach(keyFirstLevel => {
                    if (false === keyFirstLevel in availableKeys) {
                        availableKeys[keyFirstLevel] = [];
                    }
                    if (null !== element.properties[keyFirstLevel] && true === isObject(element.properties[keyFirstLevel])) {
                        Object.keys(element.properties[keyFirstLevel]).forEach(keySecondLevel => {
                            if (false === availableKeys[keyFirstLevel].includes(keySecondLevel)) {
                                availableKeys[keyFirstLevel].push(keySecondLevel);
                            }
                        });
                    }
                });
            }
        });

        return availableKeys;
    };

    const getDefaultValue = (key, userFilterFields) => {
        let defaultValue = '';
        if (true === Array.isArray(userFilterFields)) {
            for (const userFilterField of userFilterFields) {
                if (key === userFilterField.key) {
                    defaultValue = userFilterField.value;
                    break;
                }
            }
        }

        return defaultValue;
    };

    const isFilterValid = values => {
        return '' !== values.condition && '' !== values.key && '' !== values.value;
    };

    const isFormValuesValid = localFormValues => {
        let isValid = true;
        Object.keys(localFormValues).every(key => {
            if (false === isFilterValid(localFormValues[key])) {
                isValid = false;
                return false;
            }

            return true;
        });

        return isValid;
    };

    const renderFilters = () => {
        // Atlas
        if (false === isObjectEmpty(filters)) {
            return (
                <div className="py-2">
                    {renderFieldsFormGroup('Flags', 'FLAG')}
                    {renderFieldsFormGroup('Nums', 'NUM', null)}
                    {filters.in.filter(field => 'CHOICE' === field.type).map(fieldChoice => renderFieldsFormGroup(fieldChoice.name, 'CHOICE', fieldChoice.field))}
                </div>
            );
        }

        // non-Atlas
        const availableKeys = getAvailableKeys();
        return (
            Object.keys(formValues).map(formValueKey =>
                <FilterLineFormFieldLegacy
                    availableKeys={availableKeys}
                    defaults={formValues[formValueKey]}
                    handleUpdate={values => {
                        setFormValues({
                            ...formValues,
                            [formValueKey]: values,
                        });
                    }}
                    key={formValueKey}
                    submit={() => {}} // do nothing (for now)
                />
            )
        );
    }

    const renderForm = () => {
        if (false === show) {
            return;
        }

        // Atlas layers, backend-side filtering
        if (true === ['highVoltageLine', 'plot'].includes(dataLayer.layer.type)) {
            return (
                <Form className="mt-1 field-filters">
                    {renderFilters()}
                    <div className="container g-0 mt-1 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <div className="d-grid">
                                    <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                        {'highVoltageLine' === dataLayer.layer.type &&
                                         true === [MODE_KEY_ACCOUNTS, MODE_PROSPECTION].includes(userModes[country]) &&
                                            <Dropdown.Item className="d-grid" disabled={true === isLoading} onClick={() => {
                                                const value = MODE_KEY_ACCOUNTS === userModes[country] ? 1.3 : 2;
                                                // update + persist user filters
                                                let localUserFilters = copyDeep(userFilters);
                                                localUserFilters = updateUserFilters(localUserFilters, 'must', 'range', 'metadata.capaciteInjectionDispo', [value, null], false);
                                                localUserFilters = updateUserFilters(localUserFilters, 'must', 'range', 'metadata.capaciteSoutirageDispo', [value, null], false);
                                                dispatch(setUserFilters(localUserFilters));

                                                // update + persist filters
                                                let localFilters = copyDeep(filters);
                                                localFilters = updateFilters(localFilters, 'range', 'metadata.capaciteInjectionDispo', value);
                                                localFilters = updateFilters(localFilters, 'range', 'metadata.capaciteSoutirageDispo', value);
                                                setFilters(localFilters);

                                                // @TODO: understand why hot-reloading causes localUserFilters[dataLayer.layer._id] to be undefined
                                                formSubmit(localUserFilters[dataLayer.layer._id]);

                                                // otherwise, the range filters don't update their value
                                                setShow(false);
                                            }}
                                           >Preset</Dropdown.Item>
                                        }
                                        <Dropdown.Item className="d-grid" disabled={true === isLoading} onClick={() => {
                                            // reset + persist user filters
                                            const localUserFilters = copyDeep(userFilters);
                                            localUserFilters[dataLayer.layer._id] = dataLayer.layer.defaultAtlasFilters;
                                            dispatch(setUserFilters(localUserFilters));

                                            // submit with default filters
                                            formSubmit(localUserFilters[dataLayer.layer._id]);

                                            // otherwise, the range filters don't update their value
                                            setShow(false);
                                        }}>Reset</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => formSubmit(userFilters[dataLayer.layer._id])}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        // non-Atlas layers, client-side filtering
        const availableKeys = getAvailableKeys();
        return (
            <Form className="mt-3" onSubmit={event => event.preventDefault()}>
                <Form.Group>
                    {Object.keys(userFilters[dataLayer.layer._id].formValues).map(formValueKey =>
                        <FilterLineFormFieldLegacy
                            layerName={dataLayer.layer.name}
                            availableKeys={availableKeys}
                            defaults={userFilters[dataLayer.layer._id].formValues[formValueKey]}
                            handleUpdate={values => {
                                setFormValues({
                                    ...userFilters[dataLayer.layer._id].formValues,
                                    [formValueKey]: values,
                                });
                            }}
                            key={formValueKey}
                            submit={formSubmitLegacy}
                        />
                    )}
                    <div className="container g-0 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                    <Dropdown.Item className="d-grid" onClick={() => {
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        const localFormValues = {
                                            ...userFilters[dataLayer.layer._id].formValues,
                                            [lastFormValuesKey+1]: {},
                                        };
                                        setFormValues(localFormValues);

                                        // update + persist user filters
                                        _updateUserFilterLegacy(localFormValues);
                                    }}>Add filter</Dropdown.Item>
                                    {false === isObjectEmpty(formValues) && <Dropdown.Item onClick={() => {
                                        let localFormValues = copyDeep(formValues);
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        delete localFormValues[lastFormValuesKey];
                                        setFormValues(localFormValues);

                                        // update + persist user filters
                                        _updateUserFilterLegacy(localFormValues);
                                    }}>Remove filter</Dropdown.Item>}
                                    <Dropdown.Item onClick={() => {
                                        // remove additional filters, reset first
                                        const localFormValues = {
                                            ...defaultFormValues,
                                        };
                                        setFormValues(localFormValues);

                                        // reset + persist user filters
                                        _updateUserFilterLegacy(localFormValues);

                                        // trigger render
                                        updateDataLayer({
                                            ...dataLayer,
                                            'layer': layers.filter(layer => dataLayer.layer._id === layer._id).pop(),
                                        });
                                    }}>Reset</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={formSubmitLegacy}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        );
    };

    const renderFieldsFormGroup = (title, searchFieldType, fieldKey = null, isDisabled = false) => {
        let searchCondition = 'must';
        let searchType;
        switch (searchFieldType) {
            case 'FLAG':
                searchType = 'equals';
                break;
            case 'CHOICE':
                searchType = 'in';
                break;
            default: // 'NUM'
                searchType = 'range';
                break;
        }

        let localFilters;
        if ('CHOICE' === searchFieldType) {
            localFilters = filters[searchType].filter(field => field.field === fieldKey);
        } else {
            localFilters = filters[searchType].filter(field => 'CHOICE' !== field.type);
        }

        if (0 < localFilters.length) {
            return localFilters.map(field =>
                <FilterLineFormField
                    field={field}
                    isDisabled={true === isDisabled}
                    key={field.field}
                    updateValue={newValue => {
                        // update + persist user filters
                        const localUserFilters = updateUserFilters(copyDeep(userFilters), searchCondition, searchType, field.field, newValue, field.embeddedDocument, field.choices);
                        dispatch(setUserFilters(localUserFilters));
                        // update + persist filters
                        const localFilters = updateFilters(copyDeep(filters), searchType, field.field, newValue);
                        setFilters(localFilters);
                    }}
                    titleChoice={title}
                />
            );
        }
    };

    const toggleDataLayer = (hide, localLastHighlightedKey) => {
        updateDataLayer({
            ...dataLayer,
            'layer': {
                ...dataLayer.layer,
                'areFeaturesHidden': hide,
            },
        });

        setFilterKey(filterKey + 1); // force render
        setLastHighlightedKey(localLastHighlightedKey);
    };

    // reflects change on active filter count
    const updateFilters = (localFilters, searchType, key, value) => {
        localFilters[searchType] = localFilters[searchType].map(filter => {
            if (filter.field === key) {
                return {
                    ...filter,
                    'value': value,
                };
            }

            return filter;
        });

        return localFilters;
    };

    const updateUserFilters = (localUserFilters, searchCondition, searchType, key, value, embeddedDocument, choices = null) => {
        // @TODO: understand why hot-reloading causes localUserFilters?.[dataLayer.layer._id]?.[searchType] to be undefined

        // remove field
        localUserFilters[dataLayer.layer._id][searchType] = localUserFilters[dataLayer.layer._id][searchType].filter(filter => filter.key !== key);
        // add field back
        if ('' !== value) {
            localUserFilters[dataLayer.layer._id][searchType].push({
                'choices': choices, // this is a hack and clearly doesn't belong here, but we need to persist choices beyond this component
                'condition': searchCondition,
                'embeddedDocument': embeddedDocument,
                'key': key,
                'value': value,
            });
        }

        return localUserFilters;
    };

    if (null !== dataLayer.layerRef) {
        const localLastHighlightedKey = `${dataLayer.layer._id}-${dataLayer.order}`;
        return (
            <li className={`list-group-item${localLastHighlightedKey === lastHighlightedKey ? ' highlight' : ''}`} key={`${dataLayer.layer._id}-${filterKey}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {dataLayer.layer.name} <span className="badge bg-secondary ms-2">{dataLayer.layer.data.length}</span>
                    </span>
                    <span className="text-nowrap">
                        {true === map.hasLayer(dataLayer.layerRef) ?
                            <IconEye onClick={() => toggleDataLayer(true, localLastHighlightedKey)} role="button" size={20} title="Hide"/>
                            :
                            <IconEyeSlash onClick={() => toggleDataLayer(false, localLastHighlightedKey)} role="button" size={20} title="Show"/>
                        }
                        <IconFilter className="mx-1" onClick={() => {
                            setShow(!show);
                        }} role="button" size={20} title="Toggle filter"/>
                        <IconArrowBarUp onClick={() => {
                            setLastHighlightedKey(localLastHighlightedKey);
                            updateDataLayer(dataLayer, true);
                        }} role="button" size={20} title="Bring to front"/>
                    </span>
                </div>
                {renderForm()}
            </li>
        );
    }
}
